<template>
  <layout :activeIndex="activeIndex" :activeModel.sync="activeModel" :topBarItems="topBarItems" @setIndex="setIndex">
    <template #left-0>
      <left-menu :floors="floors" :system="system" @select="leftMenuSelect"></left-menu>
    </template>
    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <b-card-text>
          <label>设备名称</label>
          <b-form-input placeholder="输入设备名称查询" trim></b-form-input>
        </b-card-text>
      </b-card>
    </template>
    <template #right-0></template>
    <template #right-1>

      <b-card no-body>
        <b-table :current-page="currentPage" :fields="fields" :items="items" :per-page="perPage" hover responsive
                 striped>
          <template v-slot:cell(params)="data">
            <template v-for="(param,param_index) in data.item.params">
                <span v-if="param.param_name" :style="param.cur_value===null?'opacity:0.3':''">
                  <span class="mx-50" v-if="param_index!==0">,</span>
                  <span>{{ param.param_name.replace(data.item.equip_name + '-', '') }}</span>:
                  <span v-if="param.cur_value===null">N/A</span>
                  <span v-else>{{
                      param.unit&&(param.unit.indexOf('||') > -1) ? param.unit.split('||')[param.cur_value > 0 ? 1 : 0] : (param.cur_value + ' ' + (param.unit||''))
                    }}</span>
                </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm">查看</b-button>
              <b-button variant="success" size="sm" @click="equip_to_detail_model(data.item)" :disabled="!data.item.detail_model_key">详情</b-button>
            </div>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="items.length" align="right" first-number
                    hide-goto-end-buttons last-number next-class="next-item" prev-class="prev-item"></b-pagination>

    </template>
    <template #right-2>
      <panel-group :show-title="false" group-uid="4d468751"></panel-group>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import {reactive, toRefs, watch} from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";

export default {
  name: "water-supply-drainage",
  components: {
    LeftMenu,
    Layout,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "drainage_system/drainage/drainage",
            isDetail: false,
            dbIds: ["equipment_WSK", "equipment_ZSC", "equipment_SHS"],
            rotate: "48466,-110529,41064",
          },
        },
        {title: "设备列表"},
        {title: "数据分析"},
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      floors: [
        {
          title: "B1",
          value: -1,
          model: {
            path: "drainage_system/drainage_B1/drainage_B1",
            dbIds: ["equipment_WSKB1"],
            showDbIds: ["equipment_WSKB1", "jsf_B1_Wall", "jsf_B1_floor"],
          },
        },
        {
          title: "B2",
          value: -2,
          model: {
            path: "drainage_system/drainage_B2/drainage_B2",
            dbIds: ["equipment_WSKB2", "equipment_SHSB2-1"],
            showDbIds: ["equipment_WSKB2", "equipment_SHSB2-1", "jsf_B2_wall", "jsf_B2_floor"],
          },
        },
        {
          title: "B3",
          value: -3,
          model: {
            path: "drainage_system/drainage_B3/drainage_B3",
            dbIds: ["equipment_WSKB3", "equipment_ZSCB3-1"],
            showDbIds: ["equipment_WSKB3", "equipment_ZSCB3-1", "jsf_B3_Wall", "jsf_B3_floor"],
          },
        },
        {
          title: "B5",
          value: -5,
          model: {
            path: "drainage_system/drainage_B5/drainage_B5",
            dbIds: ["equipment_WSKB4"],
            showDbIds: ["equipment_WSKB4", "jsf_b4_wall", "jsf_b4_floor"],
          },
        },
      ],
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      if (event?.equip_name) {
        event.model.isDetail = true;
        layoutParams.activeModel = event.model;
      } else {
        event.model.isDetail = false;
        layoutParams.activeModel = event.model;
      }
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };

    const data = reactive({
      fields: [
        {label: "ID", key: "equip_id"},
        {label: "设备名称", key: "equip_name"},
        {label: "设备编号", key: "equip_meter_code"},
        {label: "楼层", key: "floor"},
        {label: "参数状态", key: "params"},
        {label: "操作", key: "action"},
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      select: 0,
    })
    watch(() => store.state.jsf35.equip_list, (list) => {
      data.items = list
      data.rows = list.length
    })

    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item)=>{
      setIndex(0)
      setTimeout(()=>{
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item)
      },1000)
    }

    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      ...toRefs(data),
      leftMenuSelect,
      setIndex,
      equip_to_detail_model
    };
  },
};
</script>

<style lang="scss">

</style>
